// extracted by mini-css-extract-plugin
export var barContainer = "BlogPostNavbar__barContainer__gjFdE";
export var barWrapper = "BlogPostNavbar__barWrapper__xQkyO";
export var column = "BlogPostNavbar__column__LsbMs";
export var flex = "BlogPostNavbar__flex__R6aAg";
export var flexColumn = "BlogPostNavbar__flexColumn__opfHr";
export var gap1 = "BlogPostNavbar__gap1__Qyx2t";
export var gap2 = "BlogPostNavbar__gap2__l_6d4";
export var gap3 = "BlogPostNavbar__gap3__Ti9gL";
export var gap4 = "BlogPostNavbar__gap4__WPohb";
export var gap5 = "BlogPostNavbar__gap5__ByhcY";
export var linkContainer = "BlogPostNavbar__linkContainer__yKa4z";
export var linkItem = "BlogPostNavbar__linkItem__j5_Ip";
export var row = "BlogPostNavbar__row__cQyAK";
export var underlinedLink = "BlogPostNavbar__underlinedLink__Lt4HB";